import React, { useState, useEffect } from 'react';
import { domainService } from '../../../../domain/services/domainService';
import { ProgressBar } from '../../../ProgressBar';
import { isDrupalSessionActive } from '../helpers/isDrupalSessionActive';

const divStyles = {
  position: 'fixed',
  top: 0,
  width: '100%',
  minHeight: 44,
  zIndex: 999999,
  background: '#fc0'
};

const captionStyles = {
  textAlign: 'center',
  fontWeight: 'bold',
  fontFamily: 'GeoEditRegular',
  padding: '0 10'
};

const buttonStyles = {
  display: 'inline-block',
  border: 'none',
  margin: '0 10',
  backgroundColor: '#000',
  color: '#fff',
  fontSize: 13,
  boxShadow: 'none',
  fontWeight: 'bold'
};

export const DrupalStaticBuildStatus = () => {
  // Don't render anything on SSR
  if (
    typeof window === 'undefined' ||
    !window.GATSBY_INSTANT_PREVIEW___RUNNING_BUILD_DATA ||
    !domainService.isRunningOnCMS() ||
    !isDrupalSessionActive()
  ) {
    return null;
  }

  const buildData = window.GATSBY_INSTANT_PREVIEW___RUNNING_BUILD_DATA;

  const [state, setState] = useState(() => {
    return {
      buildIsRunning: !!buildData.unique_id,
      buildIsAboutToFinish:
        buildData.unique_id && buildData.remaining_seconds < 2,
      percentage: buildData.percentage
    };
  });

  let timeoutId;
  let incrementPerSecond = 0;
  if (state.buildIsRunning) {
    incrementPerSecond = Math.round(
      (100 - buildData.percentage) / buildData.remaining_seconds
    );
    if (incrementPerSecond === 0) {
      incrementPerSecond = 1;
    }
  }

  const stopRefreshing = () => {
    clearTimeout(timeoutId);
    setState({
      buildIsRunning: false,
      buildIsAboutToFinish: false,
      percentage: 0
    });
  };

  useEffect(() => {
    if (state.buildIsRunning && !state.buildIsAboutToFinish) {
      // Do not use setInterval.
      // @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/ for more info
      timeoutId = setTimeout(() => {
        const percentage = state.percentage + incrementPerSecond;
        if (state.percentage < 100) {
          setState({
            buildIsRunning: state.buildIsRunning,
            buildIsAboutToFinish: state.buildIsAboutToFinish,
            percentage: percentage > 100 ? 100 : percentage
          });
        } else {
          stopRefreshing();
        }
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  });

  const message = state.buildIsAboutToFinish
    ? 'There is a preview process about to finish (no ETA available). Please, reload this page in a few seconds to see last changes.'
    : 'There is a preview process in progress. Please, keep in mind that some parts of the header, menu or footer may appear outdated until that process finishes.';

  return state.buildIsRunning ? (
    <div style={divStyles}>
      {!state.buildIsAboutToFinish && state.percentage && (
        <ProgressBar percentage={state.percentage} />
      )}
      <div style={captionStyles}>
        {message}
        &nbsp;
        <button
          type="button"
          onClick={stopRefreshing}
          onKeyPress={stopRefreshing}
          role="link"
          tabIndex={0}
          style={buttonStyles}
        >
          OK, close this box
        </button>
      </div>
    </div>
  ) : null;
};
