import React from 'react';
import PropTypes from 'prop-types';

const progressBarStyles = {
  position: 'relative',
  height: 16,
  width: '100%'
};

export const ProgressBar = ({ percentage }) => {
  const fillerWidth = percentage > 100 ? 100 : percentage;
  const fillerStyles = {
    background: '#000',
    textAlign: 'right',
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    width: `${fillerWidth}%`,
    height: '100%',
    transition: 'width .2s ease-in'
  };

  return (
    <div style={progressBarStyles}>
      <div style={fillerStyles}>{fillerWidth > 0 && `${fillerWidth}%`}</div>
    </div>
  );
};

ProgressBar.defaultProps = {
  percentage: 0
};

ProgressBar.propTypes = {
  percentage: PropTypes.number
};
